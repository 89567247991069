import React, { createContext, useContext, useState } from "react";
import ErrorDialog from "./ErrorDialog";

type ErrorContextType = {
    error: string | null;
    setError: React.Dispatch<React.SetStateAction<string | null>>;
  };

const ErrorContext = createContext<ErrorContextType | undefined>(undefined);

export const ErrorProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [error, setError] = useState<string | null>(null);

  const handleClose = () => {
    setError(null);
  };

  return (
    <ErrorContext.Provider value={{ error, setError }}>
      {children}
      {error && (
        <ErrorDialog open={error.length !== null} onClose={() => handleClose()} errorMessage={error} />
      )}
    </ErrorContext.Provider>
  );
};

export const useError = () => {
    const context = useContext(ErrorContext);
    if (!context) {
      throw new Error("useError must be used within an ErrorProvider");
    }
    return context;
  };