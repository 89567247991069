import { Configuration, LogLevel } from "@azure/msal-browser";
// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add `isEdge` to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf(`MSIE `);
const msie11 = ua.indexOf(`Trident/`);
const msedge = ua.indexOf(`Edge/`);
const firefox = ua.indexOf(`Firefox`);
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

interface DashboardConfiguration {
  debug: boolean;
  msalConfig: Configuration;
  rootAPIUrl: string;
  rootAPIList: RootAPIList;
  rootFunctionCode: string;
  applicationName: string;
}

interface RootAPIList {
  roles: string;
  employeeSync: string;
  employeeLookup: string;
  employeeUpdateDate: string;
  integrationStatus: string;
  dailyReports: string;
  salesDiffCrunchTimeReports: string;
  crunchTimeReportsColoredCells: string;
  crunchTimeReportsAddColoredCells: string;
  crunchTimeReportsDeleteColoredCells: string;
  storeInfoSource: string;
  store411NetworkInfo: string;
  employeeSyncErrors: string;
  paytronixSyncErrors: string;
  createTicket: string;
  storeWANStatus: string;
  deviceSummary: string;
  deviceStatus: string;
  storePeople: string;
  storeDailyHoursOfOpr: string;
  cardFree: string;
  uberEats: string;
  store411NetworkEdit: string;
  cardFreeInfo: string;
  store411Aggregate: string;
  homeRefresh: string;
  productCatalog: string;
  productCatalogRefresh: string;
  devDashboard: string;
  storeConfiguration: string;
  updateStoreConfigurationIpScheme: string;
  editAV: string;
  applications: string;
  createApplications: string;
  updateApplication: string;
  deleteApplication: string;
  permissions: string;
  deletePermission: string;
  editPermissions: string;
  createPermission: string;
  groups: string;
  deleteGroup: string;
  createGroup: string;
  editGroup: string;
  assignUserToGroup: string;
  groupUsers: string;
  unassignUserFromGroup: string;
  groupPermissions: string;
  deleteGroupPermission: string;
  createGroupPermission: string;
  userPermissions: string;
  unassignPermissionFromUser: string;
  assignPermissionToUser: string;
  applicationGraph: string;
  allUserPermissions: string;
  scopes: string[];
}

export let config: DashboardConfiguration = {
  debug: false,
  // Config object to be passed to Msal on creation
  msalConfig: {
    auth: {
      clientId: ``,
      authority: ``,
      redirectUri: ``, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
      postLogoutRedirectUri: ``, // Indicates the page to navigate after logout.
      clientCapabilities: [`CP1`], // this lets the resource owner know that this client is capable of handling claims challenge.
    },
    cache: {
      cacheLocation: `localStorage`,
      storeAuthStateInCookie: isIE || isEdge || isFirefox,
    },
    system: {
      // allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return;
          }
          // switch (level) {
          //     case LogLevel.Error:
          //         console.error(message);
          //         return;
          //     case LogLevel.Info:
          //         console.info(message);
          //         return;
          //     case LogLevel.Verbose:
          //         console.debug(message);
          //         return;
          //     case LogLevel.Warning:
          //         console.warn(message);
          //         return;
          //     default:
          //         return;
          // }
        },
      },
    },
  },
  rootAPIUrl: `http://localhost:7071`,
  rootFunctionCode: ``,
  rootAPIList: {
    roles: `/api/user/roles`,
    employeeSync: `/api/EmployeeSync/{id}`,
    employeeLookup: `/api/Employees/{id}`,
    employeeUpdateDate: `/api/jobRates`,
    integrationStatus: `/api/IntegrationStatus/{name}/{date}`,
    employeeSyncErrors: `/api/ErrorReportHttpFunc?startDate={startDate}&endDate={endDate}&reportName=EmployeeSync`,
    paytronixSyncErrors: `/api/paytronix?startDate={startDate}&endDate={endDate}&reportName=PaytronixMealCardSync`,
    storeInfoSource: `/api/stores`,
    createTicket: `/api/createTicket`,
    storeWANStatus: `/api/stores/wanstatus`,
    deviceSummary: `/api/stores/devicesummary`,
    deviceStatus: `/api/devicestatus`,
    store411NetworkInfo: `/api/stores/network`,
    dailyReports: `/api/DailyReporter/{name}/{date}`,
    salesDiffCrunchTimeReports: `/api/sales-diff-report/{startDate}/{endDate}`,
    crunchTimeReportsColoredCells: `/api/crunch-time-report/{startDate}/{endDate}`,
    crunchTimeReportsAddColoredCells: `/api/save-colored-cells`,
    crunchTimeReportsDeleteColoredCells: `/api/delete-colored-cells`,
    storePeople: `/api/stores/management`,
    cardFree: `/api/stores/cardfreestatus`,
    storeDailyHoursOfOpr: `/api/stores/cardfreehoo`,
    store411NetworkEdit: `/api/wanAddresses`,
    cardFreeInfo: `/api/stores/cardfreeinfo`,
    store411Aggregate: `/api/storeaggregates`,
    homeRefresh: `/api/manualrefresh`,
    productCatalog: `/api/product/catalog`,
    productCatalogRefresh: `/api/product/catalog/refresh`,
    devDashboard: `/api/devdashboard?startDate={startDate}&endDate={endDate}`,
    storeConfiguration: `/api/storeconfiguration`,
    updateStoreConfigurationIpScheme: `/api/storeconfiguration`,
    uberEats: `/api/stores/ubereatsstatus`,
    editAV: `/api/store411/{location}/avequipment`,

    applications: `/api/authorization/applications`,
    createApplications: `/api/authorization/applications`,
    updateApplication: `/api/authorization/applications`,
    deleteApplication: `/api/authorization/applications/{applicationName}`,

    permissions: `/api/authorization/applications/{applicationName}/permissions`,
    deletePermission: `/api/authorization/applications/{applicationName}/permissions/{permissionName}`,
    editPermissions: `/api/authorization/applications/{applicationName}/permissions/{permissionName}`,
    createPermission: `/api/authorization/applications/{applicationName}/permissions`,
    groups: `/api/authorization/applications/{applicationName}/groups`,
    createGroup: `/api/authorization/applications/{applicationName}/groups`,
    deleteGroup: `/api/authorization/applications/{applicationName}/groups/{groupName}`,
    editGroup: `/api/authorization/applications/{applicationName}/groups/{groupName}`,
    groupUsers: `/api/authorization/applications/{applicationName}/groups/{groupName}/users`,
    assignUserToGroup: `/api/authorization/applications/{applicationName}/groups/{groupName}/users`,
    unassignUserFromGroup: `/api/authorization/applications/{applicationName}/groups/{groupName}/users/{email}`,

    groupPermissions: `/api/authorization/applications/{applicationName}/groups/{groupName}/permissions`,
    deleteGroupPermission: `/api/authorization/applications/{applicationName}/groups/{groupName}/permissions/{permissionName}`,
    createGroupPermission: `/api/authorization/applications/{applicationName}/groups/{groupName}/permissions`,

    userPermissions: `/api/authorization/applications/{applicationName}/users/{email}/permissions`,
    unassignPermissionFromUser: `/api/authorization/applications/{applicationName}/users/{email}/permissions/{permissionName}`,
    assignPermissionToUser: `/api/authorization/applications/{applicationName}/users/{email}/permissions`,
    applicationGraph: `/api/authorization/application/{applicationName}`,
    allUserPermissions: `/api/authorization/applications/{applicationName}/users/{email}/allpermissions`,
    scopes: [`api://e088f5fb-ac21-440b-8494-85df1214f99d/roles`, `User.Read`],
  },
  applicationName: "DiabloDashboard",
};

let devUrl: string = `kind-beach-0080cc30f.5.azurestaticapps.net`;
let devDomain: string = `diablodash-test.torchys.com`;
let prodDomain: string = `diablodash.torchys.com`;
let prodUrl: string = `icy-coast-0aa161e0f.5.azurestaticapps.net`;
let releaseUrl: string = `calm-water-0f31d500f.5.azurestaticapps.net`;

if (window.location.hostname === prodUrl || window.location.hostname === prodDomain) {
  config.rootAPIUrl = `https://func-torchysdashboard-prod.azurewebsites.net`;
  config.rootFunctionCode = `CWFB367TkDmO4rUwBeeSOZB79Esm_DcuZcOMEt4VpSsBAzFumSE8Vg==`;
} else if (window.location.hostname === releaseUrl) {
  config.rootAPIUrl = `https://func-torchysdashboard-release.azurewebsites.net`;
  config.rootFunctionCode = `crVvlYCiJWceQExehdn-kAA-IfJVREHPCuI6vjWG5oUOAzFuWDkUug==`;
} else if (window.location.hostname === devUrl || window.location.hostname === devDomain) {
  config.rootAPIUrl = `https://func-torchysdashboard.azurewebsites.net`;
  config.rootFunctionCode = `D6UW0mD9LOqFqoxv96bvMMjODuVZy8PK_QOLlOJLBzrZAzFuzqOXsQ==`;
}

if (window.location.hostname === "localhost") {
  //Uncomment for dev
  //config.rootAPIUrl = `https://func-torchysdashboard.azurewebsites.net`;
  //config.rootFunctionCode = `D6UW0mD9LOqFqoxv96bvMMjODuVZy8PK_QOLlOJLBzrZAzFuzqOXsQ==`;
}

config.rootAPIList = {
  roles: `/api/user/roles?code=${config.rootFunctionCode}`,
  employeeSync: `/api/EmployeeSync/{id}?code=${config.rootFunctionCode}`,
  employeeLookup: `/api/Employees/{id}?code=${config.rootFunctionCode}`,
  employeeUpdateDate: `/api/jobRates?code=${config.rootFunctionCode}`,
  integrationStatus: `/api/IntegrationStatus/{name}/{date}?code=${config.rootFunctionCode}`,
  employeeSyncErrors: `/api/ErrorReportHttpFunc?startDate={startDate}&endDate={endDate}&reportName=EmployeeSync&code=${config.rootFunctionCode}`,
  paytronixSyncErrors: `/api/paytronix?startDate={startDate}&endDate={endDate}&reportName=PaytronixMealCardSync&code=${config.rootFunctionCode}`,
  createTicket: `/api/createTicket?code=${config.rootFunctionCode}`,
  storeInfoSource: `/api/stores?code=${config.rootFunctionCode}`,
  store411NetworkInfo: `/api/stores/network?code=${config.rootFunctionCode}`,
  storeWANStatus: `/api/stores/wanstatus?code=${config.rootFunctionCode}`,
  deviceSummary: `/api/stores/devicesummary?code=${config.rootFunctionCode}`,
  deviceStatus: `/api/devicestatus?code=${config.rootFunctionCode}`,
  dailyReports: `/api/DailyReporter/{name}/{date}?code=${config.rootFunctionCode}`,
  salesDiffCrunchTimeReports: `/api/sales-diff-report/{startDate}/{endDate}?code=${config.rootFunctionCode}`,
  crunchTimeReportsColoredCells: `/api/crunch-time-report/{startDate}/{endDate}?code=${config.rootFunctionCode}`,
  crunchTimeReportsAddColoredCells: `/api/save-colored-cells?code=${config.rootFunctionCode}`,
  crunchTimeReportsDeleteColoredCells: `/api/delete-colored-cells?code=${config.rootFunctionCode}`,
  storePeople: `/api/stores/management?code=${config.rootFunctionCode}`,
  cardFree: `/api/stores/cardfreestatus?code=${config.rootFunctionCode}`,
  storeDailyHoursOfOpr: `/api/stores/cardfreehoo?code=${config.rootFunctionCode}`,
  store411NetworkEdit: `/api/wanAddresses/?code=${config.rootFunctionCode}`,
  cardFreeInfo: `/api/stores/cardfreeinfo?code=${config.rootFunctionCode}`,
  store411Aggregate: `/api/storeaggregates?code=${config.rootFunctionCode}`,
  homeRefresh: `/api/manualrefresh?code=${config.rootFunctionCode}`,
  productCatalog: `/api/product/catalog?code=${config.rootFunctionCode}`,
  productCatalogRefresh: `/api/product/catalog/refresh?code=${config.rootFunctionCode}`,
  devDashboard: `/api/devdashboard?startDate={startDate}&endDate={endDate}&code=${config.rootFunctionCode}`,
  uberEats: `/api/stores/ubereatsstatus?code=${config.rootFunctionCode}`,
  storeConfiguration: `/api/storeconfiguration?code=${config.rootFunctionCode}`,
  updateStoreConfigurationIpScheme: `/api/storeconfiguration?code=${config.rootFunctionCode}`,
  editAV: `/api/store411/{location}/avequipment?code=${config.rootFunctionCode}`,
  applications: `/api/authorization/applications?code=${config.rootFunctionCode}`,
  createApplications: `/api/authorization/applications?code=${config.rootFunctionCode}`,
  updateApplication: `/api/?code=${config.rootFunctionCode}`,
  deleteApplication: `/api/authorization/applications/{applicationName}?code=${config.rootFunctionCode}`,
  permissions: `/api/authorization/applications/{applicationName}/permissions?code=${config.rootFunctionCode}`,
  createPermission: `/api/authorization/applications/{applicationName}/permissions?code=${config.rootFunctionCode}`,
  deletePermission: `/api/authorization/applications/{applicationName}/permissions/{permissionName}?code=${config.rootFunctionCode}`,
  editPermissions: `/api/authorization/applications/{applicationName}/permissions/{permissionName}?code=${config.rootFunctionCode}`,
  groups: `/api/authorization/applications/{applicationName}/groups?code=${config.rootFunctionCode}`,
  createGroup: `/api/authorization/applications/{applicationName}/groups?code=${config.rootFunctionCode}`,
  deleteGroup: `/api/authorization/applications/{applicationName}/groups/{groupName}?code=${config.rootFunctionCode}`,
  editGroup: `/api/authorization/applications/{applicationName}/groups/{groupName}?code=${config.rootFunctionCode}`,
  groupUsers: `/api/authorization/applications/{applicationName}/groups/{groupName}/users?code=${config.rootFunctionCode}`,
  assignUserToGroup: `/api/authorization/applications/{applicationName}/groups/{groupName}/users?code=${config.rootFunctionCode}`,
  unassignUserFromGroup: `/api/authorization/applications/{applicationName}/groups/{groupName}/users/{email}?code=${config.rootFunctionCode}`,

  groupPermissions: `/api/authorization/applications/{applicationName}/groups/{groupName}/permissions?code=${config.rootFunctionCode}`,
  deleteGroupPermission: `/api/authorization/applications/{applicationName}/groups/{groupName}/permissions/{permissionName}?code=${config.rootFunctionCode}`,
  createGroupPermission: `/api/authorization/applications/{applicationName}/groups/{groupName}/permissions?code=${config.rootFunctionCode}`,

  userPermissions: `/api/authorization/applications/{applicationName}/users/{email}/permissions?code=${config.rootFunctionCode}`,
  unassignPermissionFromUser: `/api/authorization/applications/{applicationName}/users/{email}/permissions/{permissionName}?code=${config.rootFunctionCode}`,
  assignPermissionToUser: `/api/authorization/applications/{applicationName}/users/{email}/permissions?code=${config.rootFunctionCode}`,
  applicationGraph: `/api/authorization/application/{applicationName}?code=${config.rootFunctionCode}`,
  allUserPermissions: `/api/authorization/applications/{applicationName}/users/{email}/allpermissions?code=${config.rootFunctionCode}`,
  scopes: [`api://e088f5fb-ac21-440b-8494-85df1214f99d/roles`, `User.Read`],
};

config.msalConfig.auth = {
  clientId: `0fe29f92-5c39-4deb-a9cb-9edefc95abfd`, // frontend app reg new - 0fe29f92-5c39-4deb-a9cb-9edefc95abfd
  authority: `https://login.microsoftonline.com/d399117b-c79f-4544-82d0-ab17b7a15628/`, // tenant id
  clientCapabilities: [`CP1`], // this lets the resource owner know that this client is capable of handling claims challenge.
};

if (
  window.location.hostname === prodUrl ||
  window.location.hostname === prodDomain ||
  window.location.hostname === devUrl ||
  window.location.hostname === devDomain ||
  window.location.hostname === releaseUrl
) {
  config.msalConfig.auth.redirectUri = `${window.location.protocol}//${window.location.hostname}`; // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
  config.msalConfig.auth.postLogoutRedirectUri = `${window.location.protocol}//${window.location.hostname}`; // Indicates the page to navigate after logout.
} else {
  //localhost
  config.msalConfig.auth.redirectUri = `${window.location.protocol}//${window.location.hostname}:3000`; // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
  config.msalConfig.auth.postLogoutRedirectUri = `${window.location.protocol}//${window.location.hostname}:3000`; // Indicates the page to navigate after logout.
}

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest: { scopes: string[]; resource: string } = {
  scopes: [...config.rootAPIList.scopes],
  resource: `https://management.core.windows.net/`,
};

export const appRoles = {
  EmployeeLookup: `EmployeeSync.Lookup`,
  EmployeeSyncErrors: `EmployeeSync.Manual`,
  EmployeeEffectiveDateUpdate: `EmployeeSync.UpdateEffectiveDate`,
  Integration: {
    ViewStatus: `Integrations.ViewStatus`,
    ViewSyncErrorReport: `Integrations.ViewEmployeeSyncErrorsReport`,
    CreateTickets: `Integrations.CreateTicket`,
  },
  StoreWANInfo: `Store411.ViewWanInfo`,
  StoreWANEdit: `Store411.EditWanStatus`,
  HelpPage: `EmployeeSync.Manual`,
  Reporting: `Reports.View`,
  EmployeeSyncShowRates: `EmployeeSync.ShowRates`,
  StoreInfo: {
    ViewPeople: `Store411.ViewPeople`,
    ViewStores: `Store411.ViewStores`,
    CardFreeHOO: `Store411.ViewHoursOfOperation`,
    ViewBuilding: `Store411.ViewBuilding`,
    ViewPOSTerminals: `Store411.ViewPOSTerminals`,
    EditAV: `Store411.EditAVInformation`,
    ViewAV: `Store411.ViewAVInformation`,
  },
  Home: {
    CardFreeStatus: `Home.ViewCardFreeStatus`,
    FirewallStatus: `Home.ViewWanStatus`,
    ViewPOSStatus: `Home.ViewDeviceStatus`,
    ViewUberEatsStatus: `Home.ViewUberEatsStatus`,
    ExecuteManualRefresh: `Home.ExecuteManualRefresh`,
  },
  ProductCatalog: `Product.ViewProductCatalog`,
  DevDashboard: `DevDashboard.View`,
  Config: {
    ViewIPScheme: `Config.ViewIPScheme`,
    EditIPScheme: `Config.EditIPScheme`,
  },
  Authorization: {
    Manage: `Authorization.Manage`,
    Admin: `Authorization.Admin`,
  },
  Paytronix: {
    ErrorView: `Paytronix.ErrorView`,
  },
};

export const pageNames = {
  home: `Dashboard Home`,
  logout: `Logout`,
  helpPage: `Help Page`,
  store411: `Store 411`,
  integrations: `Integrations`,
  reports: `Reports`,
  intStatus: `Integrations Status`,
  intEmployeeSync: `Emp Sync to POS`,
  intPaytronixError: `Emp Sync to Paytronix`,
  managePOSEmployee: `Manage POS Emp`,
  store411Info: `Store 411 Info`,
  store411InfoDetails: `Store 411 Info Details`,
  productCatalog: `Product Catalog`,
  devDashboard: `Dev Dashboard`,
  storeConfiguration: `Store Configuration`,
  authorization: `Authorization`,
  appInfo: `Application Info`,
};
