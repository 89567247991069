import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface ErrorDialogProps {
  open: boolean;
  onClose: () => void;
  errorMessage: string;
}

function ErrorDialog({ open, onClose, errorMessage }: ErrorDialogProps) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <span>Error</span>
        <CloseIcon sx={{ cursor: "pointer" }} onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        <Typography color="error" sx={{ fontWeight: "bold" }}>
          {errorMessage}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ErrorDialog;
